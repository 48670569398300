<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          User detail
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="General">
        <base-form :config="formConfig" v-model="user" :noButtons="true" />
        <base-form
          :config="additionalInformationConfig"
          v-model="user.additional_info"
          :noButtons="true"
        />
      </b-tab>
      <b-tab title="logs">
        <user-logs :user="user.email" />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import BaseForm from "../BaseForm.vue";
import UserLogs from "./UserLogs.vue";

export default {
  components: { BaseForm, UserLogs },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["Users/current"];
    },
    formConfig() {
      return [
        {
          title: "General information",
          elements: [
            {
              field: "first_name",
              label: "first_name",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "last_name",
              label: "last_name",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "email",
              label: "email",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "role",
              label: "role",
              type: "dropdown",
              values: ["admin", "user"],
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "address",
              label: "address",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "postal_code",
              label: "postal_code",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "city",
              label: "city",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "country",
              label: "country",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "access_code",
              label: "access_code",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
          ],
        },
      ];
    },
    additionalInformationConfig() {
      if (!this.user.additional_info) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Additional info",
          elements,
        },
      ];
    },
  },
  methods: {
    onPaginationData(data) {
      console.log(data);
    },
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Users/fetchById", this.$route.params.id);
      this.isLoading = false;
    },
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Users/update", this.user);
      this.doFetch();
    },
  },
  async created() {
    this.doFetch();
  },
};
</script>
<style lang="scss"></style>
